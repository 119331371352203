'use strict';
jQuery(document).ready(function($){

    function load_js()
    {
       var head= document.getElementsByTagName('head')[0];
       var script= document.createElement('script');
       script.src= './assets/js/dist/script.min.js';
       head.appendChild(script);
    }

    $(window).keydown(function(event){
      if(event.keyCode == 13) {
        event.preventDefault();
        return false;
      }
    });

      
        $('#login_form')
        // to prevent form from submitting upon successful validation
        .on("submit", function(ev) {
            ev.preventDefault();
            $('#result-text').removeClass('invalid');
            $('#result-text').text('');
             $('#login_form button').attr('disabled', 'true');
                 
                    
               
                        
                        var that = $('#login_form'),
                        url = that.attr("action"),
                        method = that.attr("method"),
                        data = {};
                        that.find("[name]").each( function() {
                            var that = $(this),
                                name = that.attr("name"),
                                value = that.val();
                                data[name] = value;
                        });
                        
                        $.ajax({
                            url: url,
                            type: method,
                            data: data,
                            success: function(response) {
                             
                               if(response){
                                
                                let result = JSON.parse(response);
                              
                                    if(result.length > 0){
                                        $('#main-content').replaceWith(result[0].info);
                                        load_js();
                                    } else {
                                        $('#result-text').addClass('invalid');
                                        $('#result-text').text('Your code is not valid');
                                    }
                               } else {
                                $('#result-text').addClass('invalid');
                                $('#result-text').text('Your code is not valid')
                               }
                              
                               $('#login_form button').removeAttr('disabled');
                            },
                            error: function(response){ 
                             
                                console.log(response);
                                $('#login_form button').removeAttr('disabled');
                            }

                        });
                  
        });

      //CONFIRM DAY 1 FORM
      $('#add-participant-1').on('click', function(){
        $('#participant-list-1').append(`
            <div class="flex flex-wrap mt-2 participant justify-center">
                <input type="text" required class="firstname appearance-none mb-2 lg:mb-0 shadow-sm relative block w-full lg:w-5/12 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md lg:rounded-tr-none lg:rounded-br-none  focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm" placeholder="First name">
                <input type="text" required class="lastname appearance-none shadow-sm relative block w-full lg:w-5/12 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md lg:rounded-tl-none lg:rounded-bl-none focus:outline-none focus:ring-green-500 focus:border-green-500 focus:z-10 sm:text-sm" placeholder="Last name"> 
                <svg xmlns="http://www.w3.org/2000/svg" class="remove-participant self-center cursor-pointer mt-2 lg:mt-0 h-7 lg:h-5 w-full lg:w-2/12 text-red-300 hover:text-red-900" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </div>`);
         $('#participant-list-1 .remove-participant').on('click', function(){
            $(this).parent().remove();
          })
      });

  
     
      $('#register_form_1')
      // to prevent form from submitting upon successful validation
      .on("submit", function(ev) {
          ev.preventDefault();
          $('#success-message-1').hide();
          $('#error-message-1').text('');
          $('#register_form_1 button').attr('disabled', 'true');
          var that = $('#register_form_1'),
          url = that.attr("action"),
          method = that.attr("method"),
          data = {};


          that.find("[name]").each( function() {
              var that = $(this),
                  name = that.attr("name"),
                  value = that.val();
                  data[name] = value;
          });

          var participants = [];

         $('#participant-list-1 .participant').each(function( index ) {
            var firstname = $(this).find('.firstname').val();
            var lastname = $(this).find('.lastname').val();
            participants.push({firstname,lastname})
         });
         data.participants = participants;

       
        if( $('#hotel-list input[name=hotel_stay_day_1]').is(":checked")){
          data.hotel_stay_day_1 = "YES";
        } else {
          data.hotel_stay_day_1 = "NO";
        }

        if( $('#hotel-list input[name=hotel_stay_day_2]').is(":checked")){
          data.hotel_stay_day_2 = "YES";
        } else {
          data.hotel_stay_day_2 = "NO";
        }

        if( $('#activities-list input[name=activity_may_3_welcome_cocktail]').is(":checked")){
          data.activity_may_3_welcome_cocktail = "YES";
        } else {
          data.activity_may_3_welcome_cocktail = "NO";
        }

        if( $('#activities-list input[name=activity_may_4_wedding_celebration]').is(":checked")){
          data.activity_may_4_wedding_celebration = "YES";
        } else {
          data.activity_may_4_wedding_celebration = "NO";
        }

        if( $('#activities-list input[name=activity_may_5_breakfast]').is(":checked")){
          data.activity_may_5_breakfast = "YES";
        } else {
          data.activity_may_5_breakfast = "NO";
        }


          $.ajax({
              url: url,
              type: method,
              data: data,
              success: function(response) {
                  if(response == 'success') {
                    $('#success-message-1').show();
                    $('#register_form_1').hide();
                  } else {
                    $('#register_form_1 button').attr('disabled', 'false');
                    $('#error-message-1').text('Failed, please try again.');
                  }
                 

              },
              error: function(response){
                $('#register_form_1 button').attr('disabled', 'false');
                $('#error-message-1').text('Failed, please try again.');
                console.log(response);
              }

          });
      });

      $('#register_form_2')
      // to prevent form from submitting upon successful validation
      .on("submit", function(ev) {
          ev.preventDefault();
          $('#success-message-2').hide();
          $('#error-message-1').text('');
          $('#register_form_2 button').attr('disabled', 'true');
          var that = $('#register_form_2'),
          url = that.attr("action"),
          method = that.attr("method"),
          data = {};


          that.find("[name]").each( function() {
              var that = $(this),
                  name = that.attr("name"),
                  value = that.val();
                  data[name] = value;
          });

          var participants = [];

         $('#participant-list-1 .participant').each(function( index ) {
            var firstname = $(this).find('.firstname').val();
            var lastname = $(this).find('.lastname').val();
            participants.push({firstname,lastname})
         });
         data.participants = participants;
         data.activity_may_4_wedding_celebration = "YES";
         if($('#status-2').is(":checked")){
          data.activity_may_4_wedding_celebration = "NO";
         }
          $.ajax({
              url: url,
              type: method,
              data: data,
              success: function(response) {
                  if(response == 'success') {
                    $('#success-message-1').show();
                    $('#register_form_2').hide();
                  } else {
                    $('#register_form_2 button').attr('disabled', 'false');
                    $('#error-message-2').text('Failed, please try again.');
                  }
                 

              },
              error: function(response){
                $('#register_form_2 button').attr('disabled', 'false');
                $('#error-message-2').text('Failed, please try again.');
                console.log(response);
              }

          });
      });

      $('#status')
      // to prevent form from submitting upon successful validation
      .on("change", function() {
          if($(this).is(":checked")){
            $('#message').show();
            $('#attending-form').hide();
            $(this).val('Not attending');
          } else {
            $('#message').hide();
            $('#attending-form').show();
            $(this).val('Attending');
            $("#register_form_1").trigger('reset');
          }
      });

      $('#status-2')
      // to prevent form from submitting upon successful validation
      .on("change", function() {
          if($(this).is(":checked")){
            $('#message-2').show();
          
            $(this).val('Not attending');
          } else {
            $('#message-2').hide();
           
            $(this).val('Attending');
            $("#register_form_2").trigger('reset');
          }
      });

    });